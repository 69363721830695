<template>
	<div class="section-wrapper">
		<h2 class="font-bold text-lg">Pro - Rating</h2>

		<FormKit type="rating" label="Primary label" />

		<FormKit type="rating" label="Primary label" rating-icon="apple" />

		<FormKit type="rating" label="Primary label" off-color="#d3d3d3" on-color="red" rating-icon="heart" />
	</div>
</template>

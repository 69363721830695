<script setup>
	function disabledDays(node, date) {
		// Re-implement min-date and max-date
		if (node.props.minDate && date < node.props.minDate) return true;
		if (node.props.maxDate && date > node.props.maxDate) return true;

		// If the date is a weekend, disable it.
		return date.getDay() === 0 || date.getDay() === 6;
	}
</script>

<template>
	<div class="section-wrapper">
		<h2 class="font-bold text-lg">Pro - Datepicker</h2>
		<FormKit type="datepicker" label="Date picker with text entry" :sequence="['year', 'month', 'day']" />

		<FormKit type="datepicker" name="vacation_date" label="Clearable date" placeholder="Select a date" :value="new Date()" :clearable="true" />

		<FormKit type="datepicker" name="pickup" label="Weekends disabled" placeholder="Select a workday" picker-only min-date="2023-01-01" max-date="2025-01-01" :disabled-days="disabledDays" />

		<FormKit type="datepicker" name="reservation" label="Date + Time" help="Select a time for your dinner reservation." placeholder="Select a time" format="MMM D \at hh:mm A" :sequence="['day', 'time']" picker-only />
	</div>
</template>

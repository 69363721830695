<script setup lang="ts">
	const flavors = ['Apple', 'Cherry', 'Vanilla'];
	async function mockAsyncSearch({ search, page, hasNextPage, timeout = 1000 }) {
		await new Promise((r) => setTimeout(r, timeout));
		console.log('loading fake options');
		return [
			{ label: 'First', value: 'first' },
			{ label: 'Second', value: 'second' },
			{ label: 'Third', value: 'third' },
		].filter((item) => item.label.toLowerCase().includes(search.toLowerCase()));
	}
</script>

<template>
	<div class="section-wrapper">
		<h2 class="font-bold text-lg">Pro - Autocomplete</h2>
		<FormKit type="autocomplete" label="Text single" :options="flavors" value="Apple" />

		<FormKit type="autocomplete" label="Option single" :options="flavors" selection-appearance="option" value="Apple" />

		<FormKit type="autocomplete" label="Text single async" :options="mockAsyncSearch" />

		<FormKit type="autocomplete" label="Option single async" :options="mockAsyncSearch" selection-appearance="option" />
	</div>
</template>

<template>
	<AppLayout>
		<template #primary>
			<div class="flex-col h-full w-full">
				<div class="flex-1 h-full w-full">
					<div class="flex h-full w-full min-h-98">
						<div class="flex-1 bg-gray-100 relative dark:bg-dark-900 min-w-120 z-20">
							<div class="absolute inset-0 sm:px-14 px-4 transition-all duration-500 transform overflow-y-scroll">
								<div class="w-full mx-auto flex flex-col items-center transition-all transform my-8 sm:my-4 duration-300" style="max-width: calc(99999px + 5rem)">
									<div class="flex flex-row mx-auto transition-all duration-500 transform w-full">
										<div class="bg-white rounded-lg px-7 pb-6 pt-4 sm:p-10 shadow-box-circle mx-auto transition-all duration-500 transform w-full text-left justify-start">
											<FormKit type="form" :actions="false">
												<h2>Native Inputs</h2>
												<template v-for="inputType in inputTypes" :key="inputType">
													<template v-if="inputType === 'repeater'">
														<FormKit id="repeater" label="TailwindRepeater input" :type="inputType" help="Help text for the Repeater input" :add-label="inputType === 'repeater' ? 'Add Person' : null">
															<FormKit type="text" name="name" label="Name" />
														</FormKit>
													</template>
													<template v-else>
														<template v-if="Array.isArray(inputOptions[inputType])">
															<template v-if="inputType.split('_')[1]">
																<FormKit
																	:label="'Tailwind ' + inputType + ' input'"
																	:type="inputType.split('_')[0]"
																	:placeholder="inputType + ' input placeholder'"
																	:options="inputOptions[inputType]"
																	:help="'Help text for the ' + inputType + ' input'"
																	:add-label="inputType === 'repeater' ? 'Add Person' : null"
																	multiple="true"
																/>
															</template>
															<template v-else>
																<FormKit
																	:label="'Tailwind ' + inputType + ' input'"
																	:type="inputType.split('_')[0]"
																	:placeholder="inputType + ' input placeholder'"
																	:options="inputOptions[inputType]"
																	:help="'Help text for the ' + inputType + ' input'"
																/>
															</template>
														</template>
														<template v-else>
															<FormKit
																:label="'Tailwind ' + inputType + ' input'"
																:type="inputType.split('_')[0]"
																:placeholder="inputType + ' input placeholder'"
																:help="'Help text for the ' + inputType + ' input'"
																:add-label="inputType === 'repeater' ? 'Add Person' : null"
																:multiple="inputType.split('_')[1] === 'multiple'"
															/>
														</template>
													</template>
												</template>

												<Autocomplete />
												<Datepicker />
												<Dropdown />
												<Mask />
												<Rating />
												<Repeater />
												<Slider />
												<Taglist />
												<Toggle />
												<TransferList />
											</FormKit>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</template>
	</AppLayout>
</template>

<script lang="ts" setup>
	import AppLayout from '@layouts/AppLayout.vue';
	import Autocomplete from '../KitchenSink/Autocomplete.vue';
	import Datepicker from '../KitchenSink/Datepicker.vue';
	import Dropdown from '../KitchenSink/Dropdown.vue';
	import Mask from '../KitchenSink/Mask.vue';
	import Rating from '../KitchenSink/Rating.vue';
	import Repeater from '../KitchenSink/Repeater.vue';
	import Taglist from '../KitchenSink/Taglist.vue';
	import Toggle from '../KitchenSink/Toggle.vue';
	import TransferList from '../KitchenSink/TransferList.vue';
	import Slider from '../KitchenSink/Slider.vue';

	const inputTypes = [
		'button',
		'checkbox',
		'checkbox_multiple',
		'color',
		'date',
		'datetime-local',
		'email',
		'file',
		'month',
		'number',
		'password',
		'radio',
		'range',
		'search',
		'select',
		'select_multiple',
		'stepper',
		'submit',
		'tel',
		'text',
		'textarea',
		'time',
		'url',
		'week',
	];
	const inputOptions = {
		checkbox_multiple: ['I like Tailwind', 'I also like FormKit', 'I like other things too'],
		radio: ['I like Tailwind', 'I like FormKit', 'I like everything'],
		select: ['I like Tailwind', 'I like FormKit', 'I like everything'],
		select_multiple: ['I like Tailwind', 'I like FormKit', 'I like everything'],
	};
</script>

<template>
	<div class="section-wrapper">
		<h2 class="font-bold text-lg">Pro - Toggle</h2>
		<FormKit type="toggle" />

		<FormKit type="toggle" label="Toggle Label" thumb-icon="star" />

		<FormKit type="toggle" label="Toggle Label" on-value-label="Yes" off-value-label="No" />

		<FormKit type="toggle" label="Toggle Label" on-value-label="Yes" off-value-label="No" value-label-display="inner" />
	</div>
</template>

<script setup>
	const chartData = Array(200)
		.fill(0)
		.map((_, i) => ({
			at: i * 10,
			value: Math.random() * 100 + i * 2,
		}));
</script>

<template>
	<div class="section-wrapper">
		<h2 class="font-bold text-lg">Pro - Slider</h2>
		<FormKit
			type="slider"
			label="Choose your price range"
			:value="[800, 1200]"
			tooltip="true"
			min="0"
			max="2000"
			show-input
			step="25"
			:input-attrs="{
				floatingLabel: true,
				prefixIcon: 'dollar',
			}"
			:min-input-attrs="{
				label: 'Minimum',
			}"
			:max-input-attrs="{
				label: 'Maximum',
			}"
			:marks="[
				{ at: 0, label: '$0' },
				{ at: 500, label: '$500' },
				{ at: 1000, label: '$1000' },
				{ at: 1500, label: '$1500' },
				{ at: 2000, label: '$2000' },
			]"
			mark-labels
			:chart="chartData"
		/>
		<pre>{{ chartData }}</pre>
	</div>
</template>
